import React, { FC } from "react";
import { Container, Hidden, styled } from "@mui/material";

type Props = {}

const SellerScreen: FC<Props> = (props) => {

  return (
    <>
      <img src="/seller/fv_word.png" alt="" style={{ width: "100%", marginBottom: 22 }} />

      <div style={{ justifyContent: "center", alignItems: "center", display: 'flex', marginBottom: 20, maxWidth: "100%" }}>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "90%" }}>
          <img src="/seller/download_button.png" alt="" style={{ width: "100%" }} />
        </a>
      </div>

      <img src="/seller/fv_photos.png" alt="" style={{ width: "100%" }} />

      <img src="/seller/categories_word.png" alt="" style={{ width: "100%" }} />

      <div className="loop_wrap">
        <img src="/seller/categories.png" alt="" />
        <img src="/seller/categories.png" alt="" />
      </div>

      <img src="/seller/numbers.png" alt="" style={{ width: "100%" }} />


      <img src="/seller/merit.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/steps.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/fee.png" alt="" style={{ width: "100%" }} />
      <img src="/seller/support_word.png" alt="" style={{ width: "100%" }} />

      <a href="https://publiccompany.notion.site/FAQ-0047ec671326415b968b63b474fcbda2?pvs=4" style={{ width: "100%" }} target="_blank">
        <img src="/seller/faq.png" alt="" style={{ width: "100%" }} />
      </a>

      <img src="/seller/lv_word.png" alt="" style={{ width: "100%" }} />

      <div className="loop_wrap">
        <img src="/seller/categories.png" alt="" />
        <img src="/seller/categories.png" alt="" />
      </div>

      <div style={{ justifyContent: "center", alignItems: "center", display: 'flex', marginBottom: 50, maxWidth: "100%" }}>
        <a href="https://drop-app.onelink.me/52GO/ymxxyfae" style={{ width: "90%" }}>
          <img src="/seller/download_button.png" alt="" style={{ width: "100%" }} />
        </a>
      </div>

      <div style={{ maxWidth: "444px", width: "100%", padding: "40px 0px", backgroundColor: "#000", justifyContent: 'center', alignItems: "center", display: 'flex' }}>
        <div style={{ textAlign: 'center' }}>
          <a href="https://public.st">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>運営会社</p>
          </a>
          <a href="https://forms.gle/f1AGhX1G4aZLDMDa7">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>お問い合わせ</p>
          </a>
          <a href="https://x.com/drop_cards">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>X</p>
          </a>
          <a href="https://instagram.com/drop_jp">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>Instagram</p>
          </a>
          <a href="https://drop.ec">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>購入者の方はこちら</p>
          </a>
          <a href="https://drop.ec/terms">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>利用規約</p>
          </a>
          <a href="https://drop.ec/privacy">
            <p style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}>プライバシーポリシー</p>
          </a>
        </div>
      </div>
    </>
  )

}

export default SellerScreen