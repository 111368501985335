import React, { FC } from "react";
import { Container, styled } from "@mui/material";
import TokushohouScreen from "components/TokushohouScreen"

type Props = {}
const Home: FC<Props> = (props) => {

  return (
    <Container maxWidth="sm">
      <div style={{padding: "50px 0px"}}>
        <TokushohouScreen />
      </div>
    </Container>
  )

}

export default Home