import React, { FC } from "react";
import { Container, styled } from "@mui/material";
import HomeScreen from "components/HomeScreen";

const StyledContainer = styled(Container)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "auto"
  },
}));

type Props = {}
const Home: FC<Props> = (props) => {

  return (
    <StyledContainer maxWidth="md">
      <HomeScreen />
    </StyledContainer>
  )

}

export default Home