import React, { FC } from "react";
import { Switch, Route } from "react-router-dom"
import Home from "pages/Home";
import Tokushohou from "pages/Tokushohou";
import Privacy from "pages/Privacy";
import Terms from "pages/Terms";
import Seller from "pages/Seller";

type Props = {}
const Router: FC<Props> = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/tokushohou" component={Tokushohou} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/seller" component={Seller} />
    </Switch>
  )
}

export default Router;