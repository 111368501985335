import React, { FC } from "react"
import { styled } from "@mui/material";

const Title = styled("h5")({
  marginBottom: "5px"
})

const Content = styled("p")({
  fontSize: "14px",
  margin: "0px 0px 10px 0px"
})

const Wrapper = styled("div")({
  borderBottom: "solid 1px #ECECEC",
  width: "100%"
})

type Props = {}
const TokushohouScreen: FC<Props> = (props) => {

  return (
    <>
      <div>

        <h2 style={{textAlign: "center", marginBottom: "70px"}}>特定商取引法に基づく表記</h2>

        <Wrapper>
          <Title>会社名</Title>
          <Content>Public 株式会社</Content>
        </Wrapper>

        <Wrapper>
          <Title>代表者名</Title>
          <Content>松本拓也</Content>
        </Wrapper>

        <Wrapper>
          <Title>所在地</Title>
          <Content>〒150-0001<br />東京都渋谷区神宮前六丁目23番4号 桑野ビル2階</Content>
        </Wrapper>

        <Wrapper>
          <Title>ホームページ</Title>
          <Content>https://public.st</Content>
        </Wrapper>

        <Wrapper>
          <Title>お問い合わせ</Title>
          <Content>hello@drop.ec</Content>
        </Wrapper>

        <Wrapper>
          <Title>電話番号</Title>
          <Content>上記お問い合わせまでご連絡いただけましたら遅延なく開示いたします</Content>
        </Wrapper>

        <Wrapper>
          <Title>商品の販売価格</Title>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>購入者</p>
          <Content>各商品に表記された出品者が定める価格に準じます </Content>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>販売者</p>
          <Content>サービス利用料として一取引ごとに当該取引の販売価格（消費税、送料を含む）の4%の金額をいただきます。</Content>
        </Wrapper>

        <Wrapper>
          <Title>支払い方法</Title>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>購入者</p>
          <Content>クレジットカード</Content>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>販売者</p>
          <Content>サービス利用料、決済手数料、振込手数料、事務手数料を決済金額から差し引きご指定の銀行口座にお振り込みすることでお支払いいただきます。 </Content>
        </Wrapper>

        <Wrapper>
          <Title>支払い時期</Title>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>購入者</p>
          <Content>即日</Content>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>販売者</p>
          <Content>サービス利用料、決済手数料、振込手数料、事務手数料について、弊社からお振り込みするときにお支払いいただきます。</Content>
        </Wrapper>

        <Wrapper>
        <Title>引き渡し時期</Title>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>購入者</p>
          <Content>商品の購入より4日以内に発送</Content>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>販売者</p>
          <Content>サービスに登録後、即日ご利用いただけます。</Content>
        </Wrapper>

        <Title>返品・交換について</Title>
        <Content>返金、キャンセルは受け付けておりません。</Content>
      </div>
    </>

  )

}

export default TokushohouScreen