import React, { FC } from "react";
import { styled } from "@mui/material";

const Wrapper = styled("div")({
  marginBottom: "15px",
  display: "inline-block"
})

const Title = styled("h4")({
  marginBottom: "15px"
})

const Content = styled("p")({
  fontSize: "13px",
  margin: "0px 0px 10px 0px",
  lineHeight: "1.7"
})

const ListItem = styled("li")({
  fontSize: "13px",
  margin: "0px 0px 10px 0px",
  lineHeight: "1.7"
})
type Props = {}
const TermsScreen: FC<Props> = (props) => {
  return (
    <>
      <div>
        <h2 style={{ textAlign: "center", marginBottom: "70px" }}>利用規約</h2>

        <Content>
          本利用規約（以下「本規約」といいます）には、本サービスの提供条件およびPublic株式会社（以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
        </Content>

        <Wrapper>
          <Title>第1条 適用</Title>
          <ol>
            <ListItem>
              本規約は、本サービスの提供条件および本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
            </ListItem>
            <ListItem>
              当社が本ウェブサイト上で掲載する本サービス利用に関するルール（https://drop.ec）は、本規約の一部を構成するものとします。
            </ListItem>
            <ListItem>
              本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第2条 定義
          </Title>
          <Content>
            本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
          </Content>
          <ol>
            <ListItem>
              「本サービス」とは、当社が運営するライブオークションのプラットフォーム提供サービス及びそれに付随するサービスを提供することを目的とする「DROP」（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）のことをいい、当社がユーザーに個別に提供する有料サービスを含めます。
            </ListItem>
            <ListItem>
              「本サイト」とは、ドメインが「https://drop.ec」である、本サービスのコンテンツが掲載されたウェブサイト及びアプリケーションのことを意味します。
            </ListItem>
            <ListItem>
              「本アプリ」とは、本サービスのコンテンツが掲載されたスマートフォン及びタブレット向けアプリケーションのことを意味します。
            </ListItem>
            <ListItem>
              「本コンテンツ」とは、本サイト及び本アプリ上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称のことを意味します。
            </ListItem>
            <ListItem>
              「ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。ユーザーには、購入者および販売者が含まれます。
            </ListItem>
            <ListItem>
              「商品」とは、出品者によって本サービスを通じて出品され、ユーザー間で売買される物品のことを意味します。
            </ListItem>
            <ListItem>
              「個人情報」とは、住所、氏名、電話番号等個人を特定することのできる情報の総称で、個人情報保護法第2条に規定される情報のことを意味します。
            </ListItem>
            <ListItem>
              「登録情報」とは、ユーザーが本サイトにて登録した情報の総称を意味します。
            </ListItem>
            <ListItem>
              「出品者」とは、ユーザーのうち、本サービスにおいてオークションへの出品を目的として当社所定の登録を行った個人又は法人のことを意味します。
            </ListItem>
            <ListItem>
              「購入者」とは、ユーザーのうち、出品者から商品を購入した個人又は法人のことを意味します。
            </ListItem>
            <ListItem>
              「投稿情報」とは、ユーザーが本サービスに投稿し掲載される文章、文字列、画像、コメント、動画等の情報の総称を意味します。
            </ListItem>
            <ListItem>
              「知的財産権」とは、特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利又は法律上保護される利益に係る権利のことを意味します。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第3条 ユーザー登録
          </Title>
          <ol>
            <ListItem>本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。</ListItem>
            <ListItem>当社は、当社の基準に従って、第1項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。</ListItem>
            <ListItem>前項に定める登録の完了時に、サービス利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができるようになります。</ListItem>
            <ListItem>当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録および再登録を拒否することがあり、またその理由について一切開示義務を負いません。</ListItem>
            <ListItem>未成年者（20歳未満の者をいうものとし、以下同様とします）、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</ListItem>
            <ListItem>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</ListItem>
            <ListItem>過去当社との契約に違反した者またはその関係者であると当社が判断した場合</ListItem>
            <ListItem>第18条に定める措置を受けたことがある場合</ListItem>
            <ListItem>その他、登録を適当でないと当社が判断した場合</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第4条 未成年者による利用
          </Title>

          <ol>
            <ListItem>未成年者は、会員登録の申請を含む当社サービスの利用に関する一切の行為につき、親権者等の法定代理人の同意を得た上でこれを行うものとします。未成年者が会員登録を完了した時点で、当社サービスの利用および本規約の内容について、法定代理人の同意があったものとみなします。</ListItem>
            <ListItem>未成年者の会員が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って当社サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、当該利用に関する一切の法律行為を取り消すことは出来ません。</ListItem>
            <ListItem>本規約の同意時に未成年であった会員が成年に達した後に当社サービスを利用した場合、当該会員は、当社サービスに関する一切の法律行為を追認したものとみなされます。</ListItem>
          </ol>

        </Wrapper>

        <Wrapper>
          <Title>
            第5条 登録事項の変更
          </Title>
          <Content>
            ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
          </Content>
        </Wrapper>


        <Wrapper>
          <Title>
            第6条 利用環境整備
          </Title>

          <ol>
            <ListItem>ユーザーは、本サービスを利用するために必要な機器、ソフトウェア、通信回線等を自らの責任と費用にて整備しなければなりません。推奨環境は、当社が本サイトにて別途掲載します。</ListItem>
            <ListItem>ユーザーは、本サービスを利用するにあたり必要なコンピューター・ウィルスの感染防止等のセキュリティ対策を自己の責任と費用にて講じるものとします。</ListItem>
          </ol>

        </Wrapper>


        <Wrapper>
          <Title>
            第7条 ユーザーアカウント
          </Title>

          <ol>
            <ListItem>ユーザーは、本サービスを利用するためのアカウント（以下「アカウント」といいます）を登録し、当社はアカウントを発行するものとします。ユーザーは、アカウントを第三者に開示、貸与、共有しないとともに、第三者に漏洩することのないよう厳重に管理するものとします。</ListItem>
            <ListItem>アカウントの管理不備、使用上の過誤、第三者の使用等によりユーザー自身およびその他の者が損害を被った場合、当社は一切の責任を負わないものとします。</ListItem>
            <ListItem>ユーザーのアカウントによる本サービスの利用その他の行為は、全てユーザーによる利用とみなすものとします。第三者がユーザーのアカウントを用いて本サービスを利用した場合、ユーザーはかかる利用についての利用料金の支払その他の一切の責任を負担するものとします。また、当該行為により当社が損害を被った場合、ユーザーは当該損害の全額を補填するものとします。</ListItem>
            <ListItem>ユーザーは、申込事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知しなければなりません。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第8条 パスワードおよびユーザーIDの管理
          </Title>

          <ol>
            <ListItem>ユーザーは、自己の責任において、本サービスに関するパスワードおよびユーザーIDを適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</ListItem>
            <ListItem>パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第9条 本サービスの内容
          </Title>

          <ol>
            <ListItem>
              当社は、本サービスの目的を実現するための様々な機能を提供します。ユーザーは、オークションに出品されている商品（自身が出品者として出品する商品を除く）について、入札を行うことができます。入札をするためには、第3条にもとづき登録手続きを行う必要があります。
            </ListItem>
            <ListItem>
              ユーザーは、出品者に対して、本サービス上にコメントを投稿することができます。
            </ListItem>
            <ListItem>
              本サービスのオークションへの出品期間は、当社の定める時間設定に従うものとします。
            </ListItem>
            <ListItem>
              購入者が商品を落札したことについて、本サービスからの落札完了メールを受領したときに、本サービスを通じて出品者と購入者間で行われる売買契約（以下総称して「売買契約」という）が成立するものとします。出品者及び購入者は、売買契約に基づき発生した権利・義務を第三者に譲渡、担保提供その他の処分することはできないものとします。
            </ListItem>
            <ListItem>
              本サービスを通じたオークションに出品される目的物及び、商品の発送時期、その他契約の詳細な取り決めについては、出品者が購入を希望するユーザーに対し、売買契約の成立前に明示しなければなりません。
            </ListItem>
            <ListItem>
              当社は、本規約に定める各サービスのほか、ユーザーに対し、有償又は無償で、本サービスに関連するサービス（以下「オプションサービス」といいます）を提供することができるものとします。ユーザーは、当社が提供するオプションサービスを利用するときは、当社が別途定める個別規定に従うものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第10条 出品者の権利義務
          </Title>

          <ol>
            <ListItem>
              出品者は、当社所定の手続きを得ることにより、本サービスに商品を出品できます。
            </ListItem>
            <ListItem>
              出品者は、商品説明、契約の内容、商品価格、商品価格以外に購入者側で負担が必要となる諸費用、お届け時期について誤解を与えないよう十分な情報を提供するよう務めるものとします。実際とはかけ離れた内容や、利用者を混乱させるような情報を配信、または掲載することをしてはならないものとします。
            </ListItem>
            <ListItem>
              出品者は、出品について、古物営業法、特定商取引に関する法律、不当景品類及び不当表示防止法、不正競争防止法、商標法、著作権法、消費者契約法、民法、商法その他の法律及び当社の規定を遵守しなければなりません。
            </ListItem>
            <ListItem>
              当社が本サービス上に定める出品禁止事項は、第1条第2項に規定する本サービス利用に関するルールに含まれます。
            </ListItem>
            <ListItem>
              出品者が、前4項に記載した義務を怠ったことにより、出品者、ユーザー又は第三者に生じた損害について、当社は一切責任を負わないものとします。
            </ListItem>
            <ListItem>
              商品代金の決済が完了し、購入者から当該取引に係る商品の到達が確認できてから7日が経過し、かつ、その間に購入者から当社に対して第11条第7項の通知が行われなかった場合において、全ての取引が完了したものとします。
            </ListItem>
            <ListItem>
              特定商取引法の定めに従い、出品者は特定商取引法に必要な情報を全て当社に提供しなければなりません。これらの情報について当社がユーザーから開示の請求を受けた場合には、遅滞なくその請求者に対して開示するものとし、これを出品者は予め承諾するものとします。
            </ListItem>
            <ListItem>
              出品者が、本規約で禁止されている偽物を出品する行為、詐欺行為、自己取引、関係者内での架空取引の行為を行ったことについて発覚した場合、当社は当該出品者に対して次の対応をすることができるものとします。
            </ListItem>
            <ul>
              <ListItem>
                本サービスの退会処分にすること
              </ListItem>
              <ListItem>
                本サービスの利用を制限すること
              </ListItem>
              <ListItem>
                本サービスにおける出品者への注文の一部又は全部をキャンセルすること
              </ListItem>
              <ListItem>
                出品者への支払いを保留、又は停止すること
              </ListItem>
              <ListItem>
                出品者による禁止行為への対応の結果として当社が負担した費用及び手数料並びに商品返送にかかる費用を出品者に請求すること
              </ListItem>
              <ListItem>
                商品代金に加え、取引金額の15％に相当する追加料金を違約金として請求すること（ただし、当社に生じたその他の損害の賠償を請求することを妨げるものではありません）。
              </ListItem>
              <ListItem>
                出品者は、偽物の出品や詐欺行為を知らずに行っていたとしても、当社が偽物や詐欺行為を発見した時点で、当社が前項の対応を行うことにあらかじめ承諾するものとします。また、商品が法令執行機関に提供される場合、その法令執行機関が出品者に対して商品に関する財産権の請求を行うことを予め承諾するものとします。
              </ListItem>
              <ListItem>
                売買契約が成立した後、商品の発送については、特段の理由がない限り4日以内に発送することとします。特段の理由がある場合は、商品ページに記載して、購入前に購入者が知ることができるようにしてください。
              </ListItem>
            </ul>
            <ListItem>
              出品者が購入された商品の発送を期日までに履行しない場合、当社は出品者に対し、商品代金に加え、取引金額の15％に相当する追加料金を違約金として請求します。また、出品者が商品の発送を行わなかった場合、当社は次に記載する各号の対応を取ることができるものとします。
            </ListItem>
            <ul>
              <ListItem>
                出品者の発送不履行によって当社に生じた損害を賠償請求すること
              </ListItem>
              <ListItem>
                出品者を本サービスの退会処分にすること
              </ListItem>
              <ListItem>
                出品者の、本サービスの利用を制限すること
              </ListItem>
              <ListItem>
                出品者への注文の一部又は全部をキャンセルすること
              </ListItem>
              <ListItem>
                出品者への支払いを保留、又は停止すること
              </ListItem>
            </ul>
            <ListItem>
              チャージバックによって生じる費用は、販売者が負担するものとします。
            </ListItem>
            <ListItem>
              購入者が商品落札後の決済に失敗した場合、その落札にかかる売買契約は不成立となり、出品者はその落札が成立しなかったものとみなして改めてオークションを開始することが出来ます。
            </ListItem>
            <ListItem>
              当社は、出品者が本条に規定する当社からの請求に応じない場合、当社が代理受領した商品代金等とこれを相殺することができるものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第11条 購入者の権利義務
          </Title>

          <ol>
            <ListItem>
              ユーザーは、本サービスに出品される商品を購入することができます。商品を購入するためには、当社所定の手続きにより、購入の意思を持って商品の注文を行うものとします。
            </ListItem>
            <ListItem>
              当社が定める取引可能時間内に、当社の管理するサーバーに対しユーザーからの購入の意思を示す当社所定のデータの到達が確認できなかった場合、そのデータ送信にかかる注文は無効になるものとし、ユーザーはあらかじめこれを承諾するものとします。
            </ListItem>
            <ListItem>
              売買契約が成立した場合、購入者は、当社の定める方法により商品代金と送料その他個別の商品で定める費用の合計額を一括で支払うものとします。
            </ListItem>
            <ListItem>
              購入者において未払債務が存在している場合、当社は、未払債務の回収事務を第三者に委託することができるものとします。
            </ListItem>
            <ListItem>
              当社は、ユーザーについて、購入意思がない又は悪意のある注文と当社が判断した場合、チャージバックが発生した場合、その他当社がユーザーに対して合理的な理由を示した場合（以下、本項において「問題行為等」といいます）のいずれかに該当する場合は、以下各号に記載する対応を行うことができるものとします。
            </ListItem>

            <ul>
              <ListItem>
                ユーザーを本サービスの退会処分にすること
              </ListItem>
              <ListItem>
                ユーザーの、本サービスの利用を制限すること
              </ListItem>
              <ListItem>
                ユーザーの注文の一部又は全部をキャンセルすること
              </ListItem>
              <ListItem>
                ユーザーへの払い戻し又は支払いを保留、又は停止すること
              </ListItem>
              <ListItem>
                ユーザーによる、問題行為等への対応の結果として当社が負担した費用及び手数料並びに商品返送にかかる費用をユーザーに請求すること
              </ListItem>
              <ListItem>
                当該ユーザーに対して、商品代金に加え、取引金額の15％に相当する手数料を請求すること（ただし、当社に生じたその他の損害の賠償を請求することを妨げるものではありません）。
              </ListItem>
            </ul>

            <ListItem>
              購入者は、出品者から受け取った商品が以下の各号で定めるいずれかの条件に当てはまる場合、当該商品を受け取ってから7日以内に当社に通知しなければなりません。
            </ListItem>
            <ul>
              <ListItem>
                偽物である場合
              </ListItem>
              <ListItem>
                購入または落札時の商品と同一性を欠く場合
              </ListItem>
              <ListItem>
                購入または落札時の状態と著しく異なる場合
              </ListItem>
              <ListItem>
                その他当社が認める特段の理由がある場合
              </ListItem>
            </ul>

            <ListItem>
              前項の購入者は、以下の各号に記載する内容について、当社の依頼があった場合、これに協力するものとします。
            </ListItem>
            <ul>
              <ListItem>
                商品の写真やその他の証拠を提供すること
              </ListItem>
              <ListItem>
                法令執行機関に商品を提供すること
              </ListItem>
              <ListItem>
                商品を破棄すること
              </ListItem>
              <ListItem>
                商品を当社に送ること
              </ListItem>
              <ListItem>
                その他の商品に関する調査又は処分へ協力すること
              </ListItem>
            </ul>

            <ListItem>
              前項の協力があった場合で、かつ、当社が商品について第9項に記載する各号のいずれかに該当すると認めた場合、当社は、商品のために購入者が支払ったすべての手数料及び費用を購入者へ払い戻します。
            </ListItem>

            <ListItem>
              購入者が当社からの依頼によらず、自己の意思により偽物である商品を破棄することを選択する場合、購入者は破棄する前に当社に対して書面で合意を得た後、破棄したことを証明する書類を当社へ送るものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第12条 当社の役割及び立場
          </Title>

          <ol>
            <ListItem>
              当社は、売買契約の当事者にはならず、本規約で別途に定めた場合を除き、契約の取り消し、解約、解除、返品、返金や保証など売買契約に関しては一切関与いたしません。
            </ListItem>
            <ListItem>
              購入者及び出品者は、売買契約における一切の義務を自己の費用と責任において遂行するものとします。
            </ListItem>
            <ListItem>
              当社は、ライブ配信時の映像及び投稿内容について関与しませんが、ライブ配信時の映像及び投稿内容は、当社において一定期間保存します。誹謗中傷が含まれるものや事実無根の映像又は投稿内容について利用者や第三者から調査依頼を受けた場合、調査を行った上、問題行動や発言が見受けられるユーザーに対して改善を求めます。ただし、本項は、当社が調査依頼について必ず回答を行うことを保証するものではなく、このことをユーザーは予め承諾するものとします。
            </ListItem>
            <ListItem>
              当社は、利用者が本サービスを適正に利用しているかを監視し、また、出品者が古物営業法その他の法令を遵守しているかを監視する業務について当社の裁量により行うものとし、利用者はそれに予め承諾するものとします。ただし、本条は、必ずしも当社が利用者に対して監視を行うことを保証するものではありません。
            </ListItem>
            <ListItem>
              前2項の調査又は監視の結果、利用者が本規約や法令を遵守していないと判断した場合は、利用者が適切な対応をし、利用方法について是正されたことが確認されるまで、当社は利用停止の処置をとることができるものとします。
            </ListItem>
            <ListItem>
              当社は、当社によりオークションの停止又は掲載の保留が行われた場合について、出品者及び購入者を含むユーザー並びに第三者に対して一切の責任を負いません。また、その理由についてユーザーに対し説明する義務を負わないものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第13条 当社による商品の出品について
          </Title>

          <ol>
            <ListItem>
              当社は、本サービスにおいて、商品の出品を行う場合があります。なお、その場合も、本サービスが第2条第1項に規定する本サービスの目的は変わらず、サービスの性質には何ら影響を及ぼしません。
            </ListItem>
            <ListItem>
              当社が出品した商品については、当社は本規約における出品者としての義務を追うものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第14条 利用料金
          </Title>

          <ol>
            <ListItem>
              利用者及びユーザーは、オークションを利用し、かつ売買契約が成立落札した時に支払う次項以降の手数料を除き、本サービスの利用に関して、会費等の特別な負担はありません。
            </ListItem>
            <ListItem>
              第11条第4項に定める金銭の支払いについては、以下の支払方法がご利用いただけます
            </ListItem>

            <ul>
              <ListItem>
                クレジットカード払いによる支払い
              </ListItem>
            </ul>

            <ListItem>
              第1号に記載する支払いは、返品の規定に該当する場合又は商品について偽物の出品や詐欺行為等があったと当社が判断した場合のいずれにも該当しないものについては、返金されないことを購入者は予め承諾するものとします。
            </ListItem>
            <ListItem>
              出品者は、当社が購入者から支払われる商品代金（決済事業者又は収納代行業者から支払われる商品代金に相当する金員を含みます。）を代理受領することについて承諾するものとします。
            </ListItem>
            <ListItem>
              当社は商品の売上代金のうち10%を手数料として徴収し、その残りを第16条の規定に従って出品者にお渡しいたします。
            </ListItem>
            <ListItem>
              売買契約が返品の規定に該当する場合又は商品について偽物の出品や詐欺行為等があったと当社が判断した場合のいずれにも該当しない理由により、出品者と購入者間で解約又は解除されたときは、当社に対する前号の手数料の支払い義務はなくならず、また既に支払った手数料について返金されないことを、出品者は予め承諾するものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第15条 返品等の規定
          </Title>

          <ol>
            <ListItem>
              原則として、落札後のキャンセル、返品、返金はできません。
            </ListItem>
            <ListItem>
              前項に関わらず、出品者から受け取った商品が第11条第6項に該当する場合、商品到着後7日間以内に当社にお知らせください。当社が内容を確認し、返金を認めた場合は、購入者に対して返金処理を行います。当該返金に生じた費用は、出品者に正当な理由がある場合を除き、出品者が負担するものとします。
            </ListItem>
            <ListItem>
              上記に該当しない場合の商品に対する修理又は交換等の対応は、出品者と購入者間で行うものとし、当社は一切の責任を追わないものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第16条 売上金の取扱い
          </Title>

          <ol>
            <ListItem>
              出品者は、売買契約にかかる発送が完了したものについて、当社に対して振込申請を行うことで、本サービスの売上金を受け取ることができます。なお売買契約の完了時期については、第10条第6項を参照して下さい。
            </ListItem>
            <ListItem>
              出品者は、売買契約について発送完了通知をした日の15日後から振込申請が可能になります。当社は、出品者から振込申請があった売上金について、販売者が指定する銀行口座に振り込むものとし、この場合の振込手数料は、出品者が負担します。 
            </ListItem>
            <ListItem>
              理由の如何を問わずユーザーの登録が取り消された場合または本サービスが終了した場合、登録取消時または終了時における販売者のアカウント内の売上残高については、当社が、当該残高から当社が別途定める本サービス利用料および振込手数料を差し引いた額を、登録取消月または終了月の翌月末日までに販売者が指定する銀行口座に振り込む方法により支払うものとします。登録取消時点または終了時点での売上残高が振込手数料以下の場合、販売者は売上残高に係る支払請求権を放棄し、その支払を受けないことに同意するものとします。
            </ListItem>
            <ListItem>
              出品者が指定する振込先口座情報に不備があり、当社からの前二2項に基づく支払いができない場合、当社は当該出品者にその旨を通知します。通知後においても口座情報が引き続き60日以上更新されない場合、当社の当該出品者への支払義務は消滅するものとします。
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第17条 ユーザーの責任
          </Title>

          <ol>
            <ListItem>ユーザーは、他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか第三者の権利を侵害した場合には、自己の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。</ListItem>
            <ListItem>ユーザーは、自己の責任と費用において、本サービスを利用して本サービス内に蓄積、保存したデータ等のバックアップをとるものとします。</ListItem>
            <ListItem>当社は、ユーザーが本サービスを利用して本サービス内に蓄積、保存したデータ等の保管、保存、バックアップ等に関して、当社の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第17条 権利帰属
          </Title>

          <ol>
            <ListItem>本アプリ等および本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本アプリ等または本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</ListItem>
            <ListItem>ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、および投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</ListItem>
            <ListItem>ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示および実行に関するライセンスを付与します。また、他のユーザーに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示および実行することについての非独占的なライセンスを付与します。</ListItem>
            <ListItem>ユーザーは、当社および当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第18条 禁止事項
          </Title>

          <Content>
            ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。 違反した場合、事前通知をすることなく、即時に利用停止、ライブ配信の配信又は視聴の停止、登録情報の削除等、当社が必要と判断した措置を取ることができます。
          </Content>

          <ol>
            <ListItem>ライブ配信において不適切な内容を配信していると当社が判断する行為</ListItem>
            <ListItem>ライブ配信の視聴において不適切な行為をしていると当社が判断する行為</ListItem>
            <ListItem>資金洗浄（マネーロンダリング）、その他犯罪による収益の移転防止に関する法律に違反する行為</ListItem>
            <ListItem>本サービスを預金目的または送金目的で使用する行為</ListItem>
            <ListItem>法令に違反する行為または犯罪行為に関連する行為</ListItem>
            <ListItem>当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</ListItem>
            <ListItem>公序良俗に反する行為</ListItem>
            <ListItem>当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</ListItem>
            <ListItem>本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること</ListItem>
            <ul>
              <ListItem>過度に暴力的または残虐な表現を含む情報</ListItem>
              <ListItem>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</ListItem>
              <ListItem>当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</ListItem>
              <ListItem>過度にわいせつな表現を含む情報</ListItem>
              <ListItem>差別を助長する表現を含む情報</ListItem>
              <ListItem>自殺、自傷行為を助長する表現を含む情報</ListItem>
              <ListItem>薬物の不適切な利用を助長する表現を含む情報</ListItem>
              <ListItem>反社会的な表現を含む情報</ListItem>
              <ListItem>チェーンメール等の第三者への情報の拡散を求める情報</ListItem>
              <ListItem>他人に不快感を与える表現を含む情報</ListItem>
            </ul>
            <ListItem>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</ListItem>
            <ListItem>当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</ListItem>
            <ListItem>本サービスの運営を妨害するおそれのある行為</ListItem>
            <ListItem>当社のネットワークまたはシステム等への不正アクセス</ListItem>
            <ListItem>第三者に成りすます行為</ListItem>
            <ListItem>本サービスの他の利用者のIDまたはパスワードを利用する行為</ListItem>
            <ListItem>当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</ListItem>
            <ListItem>本サービスの他の利用者の情報の収集</ListItem>
            <ListItem>当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</ListItem>
            <ListItem>反社会的勢力等への利益供与</ListItem>
            <ListItem>面識のない異性との出会いを目的とした行為</ListItem>
            <ListItem>前各号の行為を直接または間接に惹起し、または容易にする行為</ListItem>
            <ListItem>前各号の行為を試みること</ListItem>
            <ListItem>その他、当社が不適切と判断する行為</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第19条 本サービスの停止等
          </Title>

          <ol>
            <ListItem>当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</ListItem>
            <ListItem>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</ListItem>
            <ListItem>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</ListItem>
            <ListItem>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</ListItem>
            <ListItem>その他、当社が停止または中断を必要と判断した場合</ListItem>
            <ListItem>当社は、当社の都合により、本サービスの内容を変更し、または本サービスの提供を終了することができるものとします。</ListItem>
            <ListItem>当社が本サービスの提供を終了する場合、当社は当社所定の方法で、ユーザーに事前に通知するものとします。</ListItem>
            <ListItem>当社は、本条に定める本サービスの停止等によってユーザーが被った一切の損害について、その理由の如何を問わず賠償する責任を負いません。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第20条 登録抹消等
          </Title>

          <ol>
            <ListItem>当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該ユーザーについて本サービスの利用を一時的に停止し、または当該ユーザーがユーザーである場合には、ユーザーとしての登録を抹消することができます。</ListItem>
            <ListItem>本規約のいずれかの条項に違反した場合</ListItem>
            <ListItem>登録事項に虚偽の事実があることが判明した場合</ListItem>
            <ListItem>支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</ListItem>
            <ListItem>当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</ListItem>
            <ListItem>第3条第4項各号に該当する場合</ListItem>
            <ListItem>その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合</ListItem>
          </ol>
        </Wrapper>

        <Content>
          前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        </Content>


        <Wrapper>
          <Title>
            第21条 本サービス等の利用終了
          </Title>

          <ol>
            <ListItem>ユーザーは、アカウントの削除その他当社所定の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本サービスを利用することができなくなります。</ListItem>
            <ListItem>誤ってアカウントを削除した場合その他理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合、ユーザーは、アカウント、投稿データその他本サービスに蓄積した情報を利用することができなくなることを、あらかじめ承諾するものとします。</ListItem>
            <ListItem>ユーザーは、本サービス利用終了後も、当社および第三者に対する本利用契約上の一切の義務および債務(損害賠償を含みますが、これに限りません。)を免れるものではありません。</ListItem>
            <ListItem>当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。</ListItem>
            <ListItem>ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、事前に通知することなく当該ユーザーのアカウントを削除することができるものとし、ユーザーは、終了時に残存している本サービス等内の一切のコンテンツが消滅する場合があることに同意するものとします。</ListItem>
            <ListItem>ユーザーが死亡した場合、当該ユーザーのアカウント、ユーザー情報その他本サービス等に蓄積した情報は削除されるものとし、本サービスおよび本サービス等の利用権は相続の対象とはなりません。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第22条 契約終了後の処理
          </Title>
          <Content>
            当社は、理由の如何を問わず契約終了後、本サービスに係る全てのデータを削除できるものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            第23条 保証の否認および免責
          </Title>
          <ol>
            <ListItem>当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、および不具合が生じないことについて、明示または黙示を問わず何ら保証するものではありません。</ListItem>
            <ListItem>当社は、本サービスに関してユーザーが被った損害につき、現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害および弁護士費用を除く。）の範囲内でのみ損害賠償責任を負うものとし、かつ損害賠償の額は1万円を上限とするものとします。</ListItem>
            <ListItem>当社は、本アプリが全ての情報端末に対応していることを保証するものではなく、本アプリの利用に供する情報端末のOSのバージョンアップ等に伴い、本アプリの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</ListItem>
            <ListItem>本サービスまたは本アプリ等に関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第24条 秘密保持
          </Title>

          <Content>
            ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            第25条 利用者情報の取扱い
          </Title>
          <Content>
            当社によるユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー（https://drop.ec/privacy）の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。
            当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用および公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
            ユーザーは、投稿データに個人情報が含まれていたことに起因するクレーム、紛争等に対して、自己の責任と費用を持って対応するものとし、当社はこれに一切責任を負わないものとします。
            販売者は、本サービスを通じて得た利用者情報に関し、本サービスの利用の範囲内においてのみ利用することができ、それ以外の利用はできないものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            第26条 本規約等の変更
          </Title>

          <Content>
            当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期および内容を本アプリ等上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            第27条 連絡／通知
          </Title>

          <ol>
            <ListItem>本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、および本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。</ListItem>
            <ListItem>当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第28条 サービス利用契約上の地位の譲渡等
          </Title>

          <ol>
            <ListItem>ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</ListItem>
            <ListItem>当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>
            第29条 分離可能性
          </Title>
          <Content>
            本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </Content>
          <Title>
            第30条 準拠法および管轄裁判所
          </Title>

          <ol>
            <ListItem>本規約およびサービス利用契約の準拠法は日本法とします。</ListItem>
            <ListItem>本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</ListItem>
          </ol>

        </Wrapper>

        <Content>
          【2022年3月1日制定】
        </Content>
      </div>
    </>
  )

}

export default TermsScreen