import React, { FC } from "react";
import { Container, styled } from "@mui/material";
import SellerScreen from "components/SellerScreen";

type Props = {}
const Seller: FC<Props> = (props) => {

  return (
    <Container maxWidth="xs" style={{ padding: "0px" }}>
      <SellerScreen />
    </Container>
  )

}

export default Seller