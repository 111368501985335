import React, { FC } from "react"
import { styled } from "@mui/material";

const Wrapper = styled("div")({
  marginBottom: "15px",
  display: "inline-block"
})

const Title = styled("h4")({
  marginBottom: "15px"
})

const Content = styled("p")({
  fontSize: "13px",
  margin: "0px 0px 10px 0px",
  lineHeight: "1.9"
})

const ListItem = styled("li")({
  fontSize: "13px",
})

type Props = {}
const PrivacyScreen: FC<Props> = (props) => {

  return (
    <>
      <div>
        <h2 style={{textAlign: "center", marginBottom: "70px"}}>プライバシーポリシー</h2>

        <Wrapper>
          <Content>
            Public 株式会社（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、個人情報を含むお客様情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>1. お客様情報について</Title>
          <Content>
            「お客様情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
          </Content>

        </Wrapper>

        <Wrapper>
          <Title>2. 収集するお客様情報及び収集方法</Title>
          <Content>
            本サービスにおいて当社が収集するお客様情報は、その収集方法に応じて、以下のようなものとなります。
          </Content>

          <ol>
            <ListItem>
              <Content>
                <div>本サービスを通じて取得・収集させて頂く方法</div>
                <div>本サービス上でお客様自身が入力されたお客様情報を、当社は取得・収集させていただきます。</div>
              </Content>
            </ListItem>
            <ListItem>
              <Content>
                <div>電子メール、郵便、書面、電話等の手段により取得・収集させて頂く方法</div>
                <div>お客様から当社に対し、電子メール、郵便、書面、電話等の手段によって、ご提供していただいたお客様情報を当社は取得・収集させていただきます。</div>
              </Content>
            </ListItem>

            <ListItem>
              <Content>
                <div>お客様にお送りした電子メールを通じて取得・収集させて頂く方法</div>
                <div>お客様がご使用の電子メールソフトに電子メールの到着および開封の確認機能がある場合、当社からお客様にお送りした電子メールの到着および開封に関する情報を受け取らせていただき、記録させていただくことがございます。お客様がこのような情報を当社が受領することをご希望されない場合は、ご使用の電子メールソフトの確認機能を停止していただくか、確認機能のない電子メールソフトのご使用をお願いいたします。</div>
              </Content>
            </ListItem>

            <ListItem>
              <Content>
                <div>お客様が本サービスへアクセスされた際に情報を収集させて頂く方法</div>
                <div>お客様が本サービスをご利用された履歴等を収集させていただきます。これらの情報にはお客様が利用されるURL、ブラウザや携帯電話の種類やIPアドレスなどの情報を含みます。</div>
              </Content>
            </ListItem>
          </ol>
        </Wrapper>

        <Wrapper>
          <Title>3. 利用目的</Title>
          <Content>
            本サービスのサービス提供にかかわるお客様情報の具体的な利用目的は以下のとおりです。
          </Content>

          <ul>
            <li><Content>本サービスに関する登録の受付、本人確認、お客様認証、お客様設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため</Content></li>
            <li><Content>電子メール配信サービスのお申し込みの確認やメールを配信するため</Content></li>
            <li><Content>本サービスの品質向上のため</Content></li>
            <li><Content>お客様のトラフィック測定及び行動測定のため</Content></li>
            <li><Content>広告の配信、表示及び効果測定のため</Content></li>
            <li><Content>本サービスに関するご案内、お問い合わせ等への対応のため</Content></li>
            <li><Content>市場調査、分析、マーケティング及び調査研究のため</Content></li>
            <li><Content>個人を識別できない形式に加工した統計データを作成するため</Content></li>
            <li><Content>本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</Content></li>
            <li><Content>本サービスに関する規約等の変更などを通知するため</Content></li>
          </ul>
        </Wrapper>

        <Wrapper>
          <Title>4. 利用中止要請の方法</Title>
          <Content>
            お客様は、本サービスの所定の設定を行うことにより、お客様情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なおお客様情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>5. クッキーの使用について</Title>
          <Content>
            本サービスは、お客様のコンピュータの記憶装置に、「クッキー」と呼ばれるテキストファイルを送付し、保存・利用させていただくことがございます。お客様は、「クッキー」を受け取る前にブラウザが警告を出す様に設定しておく事により、お客様の判断で「クッキー」を受け取る事を拒否できますが、本サービスがご利用になれない場合があります。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>6. 外部送信、第三者提供、情報収集モジュールの有無</Title>
          <Content>
            本サービスでは、本サービスの利用状況を把握するためにGoogle Analyticsを利用しています。Google Analyticsは、クッキーを利用して利用者の情報を収集します。Google Analyticsの利用規約及びプライバシーポリシーに関する説明については、Google Analyticsのサイトをご覧ください。なお、Google Analyticsのサービス利用による損害については、当社は責任を負わないものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>7. 第三者提供</Title>
          <Content>
            当社は、あらかじめお客様の同意を得ないで、お客様情報を第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
          </Content>

          <ul>
            <li><Content>当社が利用目的の達成に必要な範囲内においてお客様情報の取扱いの全部または一部を委託する場合</Content></li>
            <li><Content>合併その他の事由による事業の承継に伴ってお客様情報が提供される場合</Content></li>
            <li><Content>第6項の定めに従って、提携先または情報収集モジュール提供者へお客様情報が提供される場合</Content></li>
            <li><Content>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</Content></li>
            <li><Content>その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合</Content></li>
          </ul>

          <Content>
            当社は、第2項にかかわらず、本サービスの品質向上、市場調査、分析、マーケティング及び調査研究を目的とし、お客様個人を特定できないよう不可逆的かつ適切な加工を行ったお客様情報を、当社と取引のある第三者に対し、データ送信、磁気媒体、紙面及び口頭等の方法により提供する場合があり、お客様はかかる第三者提供につき予め同意するものとします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>8. 個人情報の開示</Title>
          <Content>
            当社は、お客様から、個人情報保護法の定めに基づきお客様情報の開示を求められたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います（当該お客様情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>9. お客様情報の訂正及び利用停止等</Title>

          <Content>
            当社は、お客様から、(1)お客様情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、お客様ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、お客様情報の内容の訂正または利用停止を行い、その旨をお客様に通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。
          </Content>

          <Content>
            当社は、お客様から、お客様の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、お客様ご本人からのご請求であることを確認の上で、お客様情報の消去を行い、その旨をお客様に通知します。
          </Content>

          <Content>
            個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、第9項の規定は適用されません。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            10. お問い合わせ窓口
          </Title>

          <Content>
            ご意見、ご質問、その他お客様情報の取扱いに関するお問い合わせは、こちらよりお願いいたします。
          </Content>
        </Wrapper>

        <Wrapper>
          <Title>
            11.プライバシーポリシーの変更手続
          </Title>

          <Content>
            当社は、必要に応じて、本ポリシーを変更します。但し、法令上お客様の同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したお客様に対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはお客様に通知します。
          </Content>
        </Wrapper>

        <Wrapper>
          <Content>
            【2021年12月1日制定】
          </Content>
        </Wrapper>




      </div>
    </>
  )

}

export default PrivacyScreen;