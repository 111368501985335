import React, { FC } from "react"
import { styled } from "@mui/material"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
import CustomMenu from "components/CustomMenu"

const Screenshot = styled("img")(({ theme }) => ({
  height: "400px",
  borderRadius: "4px",
  width: "auto !important"
}))

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  width: "250px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "40px"
  },
}))

const Wrapper = styled("div")({
  textAlign: "center",
  margin: "0px 5px"
})

const FlexWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    margin: "80px 0px"
  },
}))

const StoreLinks = styled("div")({
  display: 'flex',
  flexFlow: 'row',
  height: 40,
  justifyContent: 'center',
  gap: 10,
})

const Logo = styled("h1")({
  display: 'inline',
  margin: 0,
  content: 'url(./drop.gif)',
})

type Props = {}
const HomeScreen: FC<Props> = (props) => {

  return (
    <>
      <CustomMenu />
      <FlexWrapper>
        <StyledCarousel
          autoPlay={true}
          infiniteLoop={true}
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
        >
          <Screenshot src="./screenshots/1.png" alt="" />
          <Screenshot src="./screenshots/2.png" alt="" />
          <Screenshot src="./screenshots/3.png" alt="" />
          <Screenshot src="./screenshots/4.png" alt="" />
        </StyledCarousel>

        <Wrapper>
          <Logo>drop (ドロップ) | コレクターが集まるライブコミュニティ</Logo>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontFamily: 'Noto Sans JP', fontWeight: '600', marginBottom: "0px" }}>コレクターが集まる</h2>
            <h2 style={{ fontFamily: 'Noto Sans JP', fontWeight: '600', marginBottom: "40px", marginTop: '0px' }}>ライブショッピングアプリ</h2>
          </div>

          <StoreLinks>
            <div style={{ width: 120 }}>
              <a href="https://drop-app.onelink.me/52GO/oxj6aoms" target="_blank" rel="noreferrer">
                <img src="./apple.png" style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }} alt="" />
              </a>
            </div>
            <div style={{ width: 120 }}>
              <a href="https://drop-app.onelink.me/52GO/oxj6aoms" target="_blank" rel="noreferrer">
                <img src='./google.png' style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }} alt="" />
              </a>
            </div>
          </StoreLinks>
        </Wrapper>
      </FlexWrapper>
    </>
  )

}

export default HomeScreen
