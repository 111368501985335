import React from 'react';
import './App.css';
import theme from "theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Router from "components/Router";
import { CssBaseline } from "@mui/material";
import { HelmetProvider } from 'react-helmet-async';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAVFdEP0nfEAjmpxoOZhfRcKHfwhZ74YAc",
  authDomain: "drop-lp.firebaseapp.com",
  projectId: "drop-lp",
  storageBucket: "drop-lp.appspot.com",
  messagingSenderId: "918326274017",
  appId: "1:918326274017:web:da44112f7cfe36ba1194a0",
  measurementId: "G-B8GR7CQS77"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
